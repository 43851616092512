import { ChangeEvent, ElementType } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { MaskProps, Masks } from 'src/components/Form/Masks';
import { FormControl, FormControlProps } from '../ui/FormControl';
import { Input, InputProps } from '../ui/Input';

type TextFieldProps = FormControlProps &
  InputProps &
  MaskProps & {
    onBeforeChange?: (value: string) => string;
  };

const TextField = (props: TextFieldProps) => {
  const { control } = useFormContext();
  const {
    name,
    mask,
    onBeforeChange,
    onChange,
    label,
    caption,
    detail,
    isDisabled,
    isRequired,
    ...fieldProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange: onChangeHookForm, onBlur, ref },
        formState: { isSubmitted },
        fieldState: { isTouched, error },
      }) => {
        const hasError = error && (isTouched || isSubmitted);

        return (
          <FormControl
            name={name}
            label={label}
            detail={detail}
            caption={hasError ? error.message : caption}
            isDisabled={isDisabled}
            isInvalid={hasError}
          >
            <Input
              {...fieldProps}
              ref={ref}
              name={name}
              id={name}
              value={value || ''}
              onChange={(originalEvent) => {
                const value = onBeforeChange
                  ? onBeforeChange(originalEvent.target.value)
                  : originalEvent.target.value;

                const event: ChangeEvent<HTMLInputElement> = {
                  ...originalEvent,
                  target: {
                    ...originalEvent.target,
                    value,
                  },
                };

                onChangeHookForm(event);
                if (onChange) onChange(event);
              }}
              onBlur={onBlur}
              isInvalid={hasError}
              isRequired={isRequired}
              disabled={isDisabled}
              type={props.type || 'text'}
              as={
                Boolean(mask)
                  ? (Masks[mask] as ElementType<HTMLInputElement>)
                  : 'input'
              }
            />
          </FormControl>
        );
      }}
    />
  );
};

TextField.displayName = 'TextField';

export { TextField };
