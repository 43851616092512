import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from 'src/utils/tailwind';

const InputWrapperConfirmationCodeVariants = cva(
  'justify-center cursor-text overflow-hidden relative px-3 flex w-full rounded-md border border-background-max bg-background-main ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-foreground-alpha focus-visible:outline-none focus-visible:ring-2 focus-visible:border-accent-main focus-visible:ring-accent-alpha focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      isInvalid: {
        true: 'border-negative-main focus-visible:border-negative-main focus-visible:ring-red-200',
        false: '',
      },
    },
  },
);

const InputConfirmationCodeVariants = cva(
  'z-10 w-5 text-center &:not(:last-child)]:mr-1 outline-0	border-0 bg-background-main ring-offset-background placeholder:text-foreground-alpha disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      size: {
        sm: 'py-1.5 text-sm',
        md: 'py-2 text-md',
        lg: 'py-2.5 text-lg',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

export interface InputConfirmationCodeProps
  extends Omit<
      React.InputHTMLAttributes<HTMLInputElement>,
      'size' | 'onChange'
    >,
    VariantProps<typeof InputWrapperConfirmationCodeVariants>,
    VariantProps<typeof InputConfirmationCodeVariants> {
  codeLength: number;
  value: string;
  onChange: (value: string) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  isInvalid?: boolean;
}

const InputConfirmationCode = ({
  className,
  size,
  codeLength,
  isInvalid,
  isRequired,
  value = '',
  isDisabled,
  onChange,
  ...props
}: InputConfirmationCodeProps) => {
  const codeFieldsRefs = React.useRef([]);

  const codeFields = Array(codeLength).fill('');

  const setFocus = (index: number) => {
    if (!codeFieldsRefs.current[index]) return;

    codeFieldsRefs.current[index].focus();
  };

  const onKeyDown = (e) => {
    const index = codeFieldsRefs.current.indexOf(e.currentTarget);

    if (
      e.key === 'ArrowLeft' &&
      codeFieldsRefs.current[index - 1] &&
      codeFieldsRefs.current[index].selectionStart === 0
    ) {
      setFocus(index - 1);
    }

    if (
      e.key === 'ArrowRight' &&
      index < value.length &&
      codeFieldsRefs.current[index + 1] &&
      codeFieldsRefs.current[index].selectionStart === 1
    ) {
      setFocus(index + 1);
    }

    if (
      e.key === 'Backspace' &&
      !codeFieldsRefs.current[index].value &&
      codeFieldsRefs.current[index - 1]
    ) {
      e.preventDefault();
      e.stopPropagation();

      onChange(value.substring(0, index - 1));

      setTimeout(() => {
        setFocus(index - 1);
      }, 0);
    }

    if (
      e.key === 'Backspace' &&
      codeFieldsRefs.current[index].value &&
      codeFieldsRefs.current[index].selectionStart === 0 &&
      codeFieldsRefs.current[index - 1]
    ) {
      e.preventDefault();
      e.stopPropagation();

      onChange(
        value.substring(0, index - 1) + value.substring(index, value.length),
      );

      setTimeout(() => {
        codeFieldsRefs.current[index - 1].setSelectionRange(0, 0);
        setFocus(index - 1);
      }, 0);
    }
  };

  const onPaste = (e) => {
    const index = codeFieldsRefs.current.indexOf(e.currentTarget);
    const data = e.clipboardData.getData('text').substring(0, codeLength);

    if (index !== 0 || value.length > 1) return;

    e.preventDefault();
    e.stopPropagation();

    onChange(data);
    setFocus(data.length - 1);
  };

  const onMouseDown = (e) => {
    const index = codeFieldsRefs.current.indexOf(e.currentTarget);

    if (index <= value.length) return;

    setFocus(value.length);

    e.preventDefault();
    e.stopPropagation();
  };

  const onChangeHandler = (e) => {
    const index = codeFieldsRefs.current.indexOf(e.currentTarget);

    if (e.target.value.length === 2 && value.length + 1 > codeLength) return;

    if (e.target.value && index == value.length) {
      onChange(value + e.target.value);
      setFocus(index + 1);

      return;
    }

    if (e.target.value && index <= value.length) {
      onChange(
        value.substring(0, index) +
          e.target.value +
          value.substring(index + 1, value.length),
      );

      return;
    }

    if (!e.target.value) {
      onChange(
        value.substring(0, index) + value.substring(index + 1, value.length),
      );

      return;
    }
  };

  const tabForIndex = (index) => {
    if (value.length === codeLength && index === codeLength - 1) return 0;
    if (value.length === index) return 0;

    return -1;
  };

  return (
    <div
      className={cn(
        InputWrapperConfirmationCodeVariants({ className, isInvalid }),
      )}
      aria-invalid={isInvalid}
      aria-required={isRequired}
      disabled={isDisabled}
      onClick={(e) => {
        if (codeFieldsRefs.current.includes(e.target)) return;

        if (value.length === codeLength) {
          setFocus(codeLength - 1);
          return;
        }

        codeFieldsRefs.current[value.length].setSelectionRange(1, 1);
        setFocus(value.length);
      }}
      {...props}
    >
      {codeFields.map((_, index) => (
        <input
          disabled={isDisabled}
          key={index}
          className={cn(InputConfirmationCodeVariants({ size }))}
          ref={(element) => codeFieldsRefs.current.push(element)}
          maxLength={index !== 0 ? 2 : codeLength}
          placeholder="○"
          type="tel"
          inputMode="numeric"
          onPaste={onPaste}
          tabIndex={tabForIndex(index)}
          aria-hidden={!!tabForIndex(index)}
          autoComplete="off"
          onKeyDown={onKeyDown}
          onMouseDown={onMouseDown}
          onChange={onChangeHandler}
          data-lpignore="true"
          value={value[index] || ''}
          data-testid={`input-code-${index}`}
        />
      ))}
    </div>
  );
};

InputConfirmationCode.displayName = 'InputConfirmationCode';

export { InputConfirmationCode };
