const createUUID = () => {
  let date = new Date().getTime();

  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const result = (date + Math.random() * 16) % 16 | 0;
    date = Math.floor(date / 16);

    return (c === 'x' ? result : (result & 0x3) | 0x8).toString(16);
  });

  return uuid;
};

export default createUUID;
