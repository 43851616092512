import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';

import { cn } from 'src/utils/tailwind';

const inputVariants = cva(
  'px-3 flex w-full rounded-md border border-background-max bg-background-main ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-foreground-alpha focus-visible:outline-none focus-visible:ring-2 focus-visible:border-accent-main focus-visible:ring-accent-alpha focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50',
  {
    variants: {
      size: {
        sm: 'py-1.5 text-sm',
        md: 'py-2 text-md',
        lg: 'py-2.5 text-lg',
      },
      isInvalid: {
        true: 'border-negative-main focus-visible:border-negative-main focus-visible:ring-red-200',
        false: '',
      },
    },
    defaultVariants: {
      size: 'md',
    },
  },
);

export interface InputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>,
    VariantProps<typeof inputVariants> {
  isRequired?: boolean;
  as?: React.ElementType;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, size, isInvalid, isRequired, as, ...props }, ref) => {
    const Component = as || 'input';

    return (
      <Component
        className={cn(inputVariants({ className, size, isInvalid }))}
        aria-invalid={isInvalid}
        aria-required={isRequired}
        ref={ref}
        {...props}
      />
    );
  },
);

Input.displayName = 'Input';

interface InputLabelProps {
  children: React.ReactElement | string;
  name: string;
  isInvalid?: boolean;
  className?: string;
}

const InputLabel = React.forwardRef<HTMLLabelElement, InputLabelProps>(
  ({ children, name, className, isInvalid }, ref) => (
    <label
      htmlFor={name}
      ref={ref}
      className={cn(
        'font-sans text-sm font-medium leading-4 text-foreground-main',
        { 'text-negative-most': isInvalid },
        className,
      )}
    >
      {children}
    </label>
  ),
);

InputLabel.displayName = 'InputLabel';

export { Input, InputLabel };
