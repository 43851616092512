import { joiResolver } from '@hookform/resolvers/joi';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { RadioGroupField } from 'src/components/Form/RadioGroupField';
import { TextField } from 'src/components/Form/TextField';
import { Button } from 'src/components/ui/Button';
import { Text } from 'src/components/ui/Text';
import { Title } from 'src/components/ui/Title';
import { useToast } from 'src/components/ui/Toast/use-toast';
import { useRequest } from 'src/hooks/useRequest';
import {
  SendTokenSchema,
  SendTokenSchemaFields,
} from 'src/schemas/SendTokenSchema';
import { sendToken } from 'src/services/CustomerAPI';

const SendTokenForm = ({
  onSuccess,
}: {
  onSuccess: ({ step, document, contact }) => void;
}) => {
  const { toast } = useToast();
  const methods = useForm<SendTokenSchemaFields>({
    mode: 'onChange',
    resolver: joiResolver(SendTokenSchema),
    defaultValues: {
      document: '',
      communicationChannel: 'email',
    },
  });

  const { executeRequest, isLoading } = useRequest(sendToken, {
    onSuccess: (data) => {
      const contact =
        methods.getValues('communicationChannel') === 'email'
          ? data.email.address
          : data.phone.address;

      onSuccess({
        step: 'VALIDATE_TOKEN',
        document: methods.getValues('document'),
        contact,
      });
    },
    onError: () => {
      toast({
        title: 'Erro',
        description:
          'Não foi possível enviar o código de acesso para o contato selecionado, tente com outra opção ou entre em contato pelo nosso atendimento',
        variant: 'destructive',
      });
    },
  });

  const onSubmit = async (data: FieldValues) => {
    await executeRequest({
      ...data,
      document: data.document.replaceAll('.', '').replace('-', ''),
    });
  };

  return (
    <div>
      <Title size="lg">Olá, qual seu CPF?</Title>
      <Text className="text-foreground-subtle">
        Vamos enviar um código para você acessar no contato selecionado
      </Text>

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="my-10 flex flex-col gap-5"
          noValidate
        >
          <TextField
            label="CPF"
            name="document"
            type="tel"
            mask="cpf"
            required
          />

          <RadioGroupField
            label="Onde deseja receber o código de acesso?"
            options={[
              { value: 'email', label: 'E-mail' },
              { value: 'sms', label: 'Celular' },
            ]}
            direction="horizontal"
            name="communicationChannel"
          />

          <div>
            <Button
              variant="solid"
              type="submit"
              isLoading={isLoading}
            >
              Continuar
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export { SendTokenForm };
