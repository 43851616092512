import Joi from 'joi';
import { cpfValidator } from 'src/utils/validators';

export const ValidateTokenSchema = Joi.object({
  document: Joi.string().required().custom(cpfValidator).messages({
    'any.required': 'O CPF é obrigatório',
    'string.empty': 'O CPF é obrigatório',
    'cpf.invalid': 'O CPF está inválido',
  }),
  token: Joi.string().min(6).max(6).required().messages({
    'any.required': 'O código de confirmação é obrigatório',
    'string.empty': 'O código de confirmação é obrigatório',
    'string.min': 'O código de confirmação é obrigatório',
    'string.max': 'O código de confirmação está inválido',
  }),
});

export interface ValidateTokenSchemaFields {
  document: string;
  token: string;
}
