const camelToSnakeCase = (
  content: object | Array<object> | unknown,
): object | Array<object> | unknown => {
  const params: { [x: string]: unknown } = {};

  if (typeof content !== 'object' || content === null) return content;

  if (Array.isArray(content))
    return content.map((item) => camelToSnakeCase(item));

  Object.keys(content).forEach((key) => {
    const snakeCaseKey = key
      .split(/(?=[A-Z])/)
      .join('_')
      .toLowerCase();
    let value: unknown = content[key as keyof typeof content];

    value = camelToSnakeCase(value);

    params[snakeCaseKey] = value;
  });

  return params;
};

export default camelToSnakeCase;
