import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect, useState } from 'react';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Button } from 'src/components/ui/Button';
import { Text } from 'src/components/ui/Text';
import { Title } from 'src/components/ui/Title';
import { useToast } from 'src/components/ui/Toast/use-toast';
import { useRequest } from 'src/hooks/useRequest';
import {
  ValidateTokenSchema,
  ValidateTokenSchemaFields,
} from 'src/schemas/ValidateTokenSchema';
import { validateToken } from 'src/services/CustomerAPI';
import { TextFieldConfirmationCode } from '../Form/TextFieldConfirmationCode';

const ValidateTokenForm = ({
  onSuccess,
  onChangeContactType,
  document,
  contact,
}: {
  onSuccess: () => void;
  onChangeContactType: (step: string) => void;
  document: string;
  contact: string;
}) => {
  const { toast } = useToast();
  const [timer, setTimer] = useState(60);
  const methods = useForm<ValidateTokenSchemaFields>({
    mode: 'onChange',
    resolver: joiResolver(ValidateTokenSchema),
    defaultValues: {
      document: document.replaceAll('.', '').replace('-', ''),
    },
  });

  const { executeRequest, isLoading } = useRequest(validateToken, {
    onSuccess,
    onError: () => {
      toast({
        title: 'Erro',
        description:
          'Não foi possível validar o código de acesso, se necessário você poderá enviar o código novamente',
        variant: 'destructive',
      });
    },
  });

  const onSubmit = (data: FieldValues) => {
    executeRequest(data);
  };

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer - 1 === 0) clearInterval(timerInterval);

        return prevTimer - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, []);

  return (
    <div>
      <Title size="lg">Qual o código?</Title>
      <Text
        className="text-foreground-subtle"
        data-testid="instructions"
      >
        Digite o código que enviamos para: {contact}
      </Text>

      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="my-10 flex flex-col gap-5"
          noValidate
        >
          <TextFieldConfirmationCode
            label="Insira o código"
            codeLength={6}
            name="token"
          />

          <Button
            type="button"
            variant="ghost"
            className="mx-auto block"
            disabled={timer > 0}
            size="sm"
            onClick={() => onChangeContactType('SEND_TOKEN')}
          >
            {timer > 0
              ? `Aguarde ${timer} segundos para escolher outro meio de validação`
              : 'Escolher outro meio de validação'}
          </Button>

          <Button
            variant="solid"
            type="submit"
            isLoading={isLoading}
          >
            Entrar
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

export { ValidateTokenForm };
