import Joi from 'joi';
import { cpfValidator } from 'src/utils/validators';

export const SendTokenSchema = Joi.object({
  document: Joi.string().required().custom(cpfValidator).messages({
    'any.required': 'O CPF é obrigatório',
    'string.empty': 'O CPF é obrigatório',
    'cpf.invalid': 'O CPF está inválido',
  }),
  communicationChannel: Joi.string().required().messages({
    'any.required': 'O tipo de contato é obrigatório',
    'string.empty': 'O tipo de contato é obrigatório',
  }),
});

export interface SendTokenSchemaFields {
  document: string;
  communicationChannel: string;
}
