import { CustomHelpers } from 'joi';

const isValidCPF = (cpf: string) => {
  let sum = 0;

  const clearedCPF = cpf.replaceAll('.', '').replace('-', '');
  const invalidCPFs = ['01234567890', '12345678901'];

  for (let i = 0; i < 10; i++) invalidCPFs.push(i.toString().repeat(11));

  if (clearedCPF.length !== 11 || invalidCPFs.indexOf(clearedCPF) > -1)
    return false;

  for (let i = 1; i <= 9; i++) {
    sum = sum + parseInt(clearedCPF.substring(i - 1, i)) * (11 - i);
  }

  let rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(clearedCPF.substring(9, 10))) return false;

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum = sum + parseInt(clearedCPF.substring(i - 1, i)) * (12 - i);
  }

  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(clearedCPF.substring(10, 11))) return false;

  return true;
};

const cpfValidator = (cpf: string, helper: CustomHelpers<unknown>) => {
  if (!cpf) return helper.error('cpf.required');
  if (!isValidCPF(cpf)) return helper.error('cpf.invalid');

  return cpf;
};

export default cpfValidator;
