import * as RadioGroupPrimitive from '@radix-ui/react-radio-group';
import { Controller, useFormContext } from 'react-hook-form';

import { cn } from 'src/utils/tailwind';
import { InputLabel } from '../ui/Input';
import { RadioGroup, RadioGroupItem } from '../ui/RadioGroup';

type RadioOption = {
  label: React.ReactElement | string;
  value: string;
  isDisabled?: boolean;
  className?: string;
};

export type RadioGroupFieldProps = React.ComponentPropsWithoutRef<
  typeof RadioGroupPrimitive.Root
> & {
  label: string;
  options: RadioOption[];
  name: string;
  onChange?: (value: string) => void;
  direction: 'horizontal' | 'vertical';
};

const RadioGroupField = (props: RadioGroupFieldProps) => {
  const { control } = useFormContext();
  const { name, label, onChange, options, direction, ...fieldProps } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { onChange: onChangeHookForm, onBlur, value },
        formState: { isSubmitted },
        fieldState: { error, isTouched },
      }) => (
        <div>
          <InputLabel
            className="mb-4 block"
            name={name}
          >
            {label}
          </InputLabel>

          <RadioGroup
            {...fieldProps}
            name={name}
            id={name}
            onBlur={onBlur}
            onValueChange={(value) => {
              onChangeHookForm(value);

              if (onChange) onChange(value);
            }}
            className={cn('flex gap-4', {
              'flex-col': direction === 'vertical',
            })}
          >
            {options.map((option) => (
              <div
                key={option.value}
                className={cn('flex items-center gap-2', option.className)}
              >
                <RadioGroupItem
                  value={option.value}
                  id={`${name}-${option.value}`}
                  checked={option.value === value}
                  disabled={option.isDisabled}
                />

                <label
                  htmlFor={`${name}-${option.value}`}
                  className="font-regular flex-1 font-sans text-base leading-5 peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                >
                  {option.label}
                </label>
              </div>
            ))}
          </RadioGroup>

          {error && (isTouched || isSubmitted) && (
            <span
              className={cn(
                'font-regular col-span-3 mt-1 font-sans text-xs leading-4 text-negative-most',
              )}
            >
              {error.message}
            </span>
          )}
        </div>
      )}
    />
  );
};

export { RadioGroupField };
