type ContentType = object | Array<object> | string | null;

const snakeToCamelCase = (
  content: ContentType,
): object | Array<object> | string | null => {
  const params: { [x: string]: unknown } = {};

  if (!content) return null;

  if (typeof content !== 'object') {
    return `${content}`.replace(/_([a-z])/gi, (_matcher, $1) =>
      $1.toUpperCase(),
    );
  }

  if (Array.isArray(content))
    return content.map((item) => {
      if (typeof item !== 'object') {
        return item;
      }

      return snakeToCamelCase(item);
    });

  Object.keys(content).forEach((key) => {
    const camelCaseKey = key.replace(/_([a-z])/gi, (_matcher, $1) =>
      $1.toUpperCase(),
    );

    const value: unknown = content[key as keyof typeof content];

    params[camelCaseKey] =
      typeof value === 'object' ? snakeToCamelCase(value as object) : value;
  });

  return params;
};

export default snakeToCamelCase;
