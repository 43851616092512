import { Controller, useFormContext } from 'react-hook-form';

import { FormControl, FormControlProps } from '../ui/FormControl';
import {
  InputConfirmationCode,
  InputConfirmationCodeProps,
} from '../ui/InputConfirmationCode';

type TextFieldConfirmationCodeProps = Omit<FormControlProps, 'onChange'> &
  Omit<InputConfirmationCodeProps, 'value' | 'onChange'> & {
    onBeforeChange?: (value: string) => string;
    onChange?: (value: string) => void;
  };

const TextFieldConfirmationCode = (props: TextFieldConfirmationCodeProps) => {
  const { control } = useFormContext();
  const {
    name,
    codeLength,
    onBeforeChange,
    onChange,
    label,
    caption,
    detail,
    isDisabled,
    isRequired,
    ...fieldProps
  } = props;

  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange: onChangeHookForm, onBlur },
        formState: { isSubmitted },
        fieldState: { isTouched, error },
      }) => {
        const hasError = error && (isTouched || isSubmitted);

        return (
          <FormControl
            name={name}
            label={label}
            detail={detail}
            caption={hasError ? error.message : caption}
            isDisabled={isDisabled}
            isInvalid={hasError}
          >
            <InputConfirmationCode
              {...fieldProps}
              name={name}
              id={name}
              value={value || ''}
              className="tracking-[16px]"
              onChange={(value) => {
                onChangeHookForm(value);

                if (onChange) onChange(value);
              }}
              onBlur={onBlur}
              isInvalid={hasError}
              isRequired={isRequired}
              disabled={isDisabled}
              codeLength={codeLength}
            />
          </FormControl>
        );
      }}
    />
  );
};

TextFieldConfirmationCode.displayName = 'TextFieldConfirmationCode';

export { TextFieldConfirmationCode };
