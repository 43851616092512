import { GetServerSideProps } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { SendTokenForm } from 'src/components/Auth/SendTokenForm';
import { ValidateTokenForm } from 'src/components/Auth/ValidateTokenForm';
import { Lia } from 'src/components/ui/Lia';
import { Text } from 'src/components/ui/Text';
import { useAmplitude } from 'src/providers/AmplitudeProvider';
import { getCurrentCustomer } from 'src/services/CustomerAPI';
import createUUID from 'src/utils/functions/uuid';

export default function SignInPage() {
  const router = useRouter();
  const [step, setStep] = useState('SEND_TOKEN');
  const [document, setDocument] = useState('');
  const [contact, setContact] = useState('');
  const returnUrl = router.query.return as string | undefined;

  const { logEvent, init } = useAmplitude();

  useEffect(() => {
    init({
      userId: createUUID(),
    });
  }, []);

  return (
    <div className="h-screen w-screen bg-[url('/assets/login-pattern.png')] bg-cover bg-no-repeat">
      <div className="flex h-screen flex-col justify-between border-b-[68px] border-b-accent-main bg-background-main px-5 py-10 sm:max-w-xl sm:border-b-0 sm:px-16 sm:py-20">
        <Lia className="scale-150" />

        {step === 'SEND_TOKEN' && (
          <SendTokenForm
            onSuccess={({ step, document, contact }) => {
              setStep(step);
              setDocument(document);
              setContact(contact);
            }}
          />
        )}

        {step === 'VALIDATE_TOKEN' && (
          <ValidateTokenForm
            onSuccess={() => {
              logEvent({ event: 'customer_signed_in' });
              router.push(returnUrl || '/minha-conta');
            }}
            onChangeContactType={(step: string) => setStep(step)}
            document={document}
            contact={contact}
          />
        )}

        <Text className="text-foreground-subtle">
          Precisando de ajuda?{' '}
          <Link
            href="https://wa.me/5508009425507"
            target="_blank"
            className="block font-medium underline sm:inline-block"
          >
            Fale conosco no 0800 942 5507
          </Link>
        </Text>
      </div>
    </div>
  );
}

export const getServerSideProps: GetServerSideProps = async ({
  req,
  query,
}) => {
  const returnUrl = query?.return as string | undefined;

  try {
    await getCurrentCustomer(req.headers.cookie);

    return {
      redirect: {
        destination: returnUrl || '/minha-conta',
        permanent: false,
      },
    };
  } catch (e) {
    if (e?.response?.status === 401) {
      return { props: {} };
    }

    if (e?.response?.status === 404) {
      return {
        notFound: true,
      };
    }

    throw e;
  }
};
