import { useEffect, useState } from 'react';

type RequestOptionsType = {
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
  onFinally?: () => void;
  enabled?: boolean;
  initialData?: any;
};

export const useRequest = (
  request: ((params?: any) => Promise<any>) | Array<() => Promise<any>>,
  requestOptions?: RequestOptionsType,
) => {
  const { enabled, initialData = {}, ...options } = requestOptions || {};

  const [isLoading, setIsLoading] = useState(Boolean(enabled));
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(null);

  const clearError = () => setIsError(false);

  const fetch = async (params?: any) => {
    try {
      setIsLoading(true);

      const response = Array.isArray(request)
        ? await Promise.all(request.map((r) => r()))
        : await request(params);

      setData(response);
      setIsSuccess(true);

      if (options.onSuccess) {
        options.onSuccess(response);
      }
    } catch (error) {
      setIsError(true);

      if (options.onError) {
        options.onError(error);
      }
    } finally {
      setIsLoading(false);

      if (options.onFinally) {
        options.onFinally();
      }
    }
  };

  const executeRequest = (params?: any) => {
    if (isLoading) return;

    return fetch(params);
  };

  useEffect(() => {
    if (!enabled) return;

    fetch(initialData);
  }, [enabled]);

  return {
    data,
    isLoading,
    isSuccess,
    isError,
    executeRequest,
    clearError,
  };
};
