import axios, { AxiosError } from 'axios';
import { CardType, CustomerType, PaymentType } from 'src/types';
import { PROTECTED_PAGES } from 'src/utils/auth';

import { camelToSnakeCase, snakeToCamelCase } from 'src/utils/functions';

const customerAPI = axios.create({
  baseURL:
    process.env.CUSTOMER_API_URL || process.env.NEXT_PUBLIC_CUSTOMER_API_URL,
  withCredentials: true,
});

const isPublicPage = () => {
  const path = window.location.pathname;

  return !PROTECTED_PAGES.some((page) => path.includes(page));
};

customerAPI.interceptors.request.use(
  (config) => {
    if (config.params) {
      config.params = camelToSnakeCase(config.params);
    }

    if (config.data) {
      config.data = camelToSnakeCase(config.data);
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

customerAPI.interceptors.response.use(
  (response) => {
    if (
      response.data &&
      response.headers['content-type'].indexOf('application/json') > -1
    ) {
      response.data = snakeToCamelCase(response.data);
    }

    return response;
  },
  (error: AxiosError) => {
    if (
      error?.response?.status === 401 &&
      typeof window !== 'undefined' &&
      !isPublicPage()
    ) {
      window.location.href = `/?return=${window.location.href}`;
    }

    return Promise.reject(error);
  },
);

type SendAuthTokenParams = {
  document: string;
  communicationChannel: string;
};

export const sendToken = async (params: SendAuthTokenParams) => {
  const { data } = await customerAPI.post(`/auth/send_token`, params);

  return data;
};

type ValidateAuthTokenParams = {
  document: string;
  token: string;
};

export const validateToken = async (
  params: ValidateAuthTokenParams,
): Promise<CustomerType> => {
  const { data } = await customerAPI.post(`/auth/sign_in`, params);

  return data;
};

export const getCurrentCustomer = async (cookies) => {
  const { data } = await customerAPI.get('/auth/current', {
    headers: { Cookie: cookies },
  });

  return data;
};

export const signOut = async (cookies) => {
  const { data } = await customerAPI.delete('/auth/sign_out', {
    headers: { Cookie: cookies },
  });

  return data;
};

export const getOrders = async (cookies) => {
  const { data } = await customerAPI.get('/orders', {
    params: {
      perPage: 10000,
      page: 1,
    },
    headers: { Cookie: cookies },
  });

  return data;
};

export const getOrder = async (cookies, id: string) => {
  const { data } = await customerAPI.get(`/orders/${id}`, {
    headers: { Cookie: cookies },
  });

  return data;
};

type DisableCardParamsType = {
  creditCardId: string;
  billingId: string;
};

export const disableCard = async (
  params: DisableCardParamsType,
): Promise<CardType> => {
  const { data } = await customerAPI.put(
    `/cards/${params.creditCardId}/disable`,
    { billingId: params.billingId },
  );

  return data;
};

type CreditCardCreationParamsType = {
  cardToken: string;
  billingId: string;
};

export const createCreditCard = async (
  params: CreditCardCreationParamsType,
): Promise<CardType> => {
  const { data } = await customerAPI.post(`/cards`, params);

  return data;
};

type PaymentCreationParamsType = {
  billId: string;
  configs: {
    paymentMethod: string;
    cardToken?: string;
  };
};

export const createPayment = async (
  params: PaymentCreationParamsType,
): Promise<PaymentType> => {
  const { data } = await customerAPI.post(`/payments`, params);

  return data;
};
